export function FutureProjects(){
    return <section>
    <div className="topSection">
        <h1 className="paddingH">Future Projects</h1>
        <h2>I'm currently working on multiple projects for the Chinese market, the English app is nearly ready to be launched. The mental health app is the far larger project and is in mid-development.</h2>
    </div>
        <div className="containerFluid">
            <div className="row">
                <div className="col">
                    <img src="assets/images/smartphoneJulie.png" className="img-fluid" alt="juliesEnglish" />
                </div>
                <div className="col">
                    <p>This will be a simple APP to read stories with options to change the difficulty of the English.</p>
                </div>
                <div className="col">
                    <img src="assets/images/smartphoneZhuYi.png" className="img-fluid" alt="zhuyixinli" />
                </div>
                <div className="col">
                    <p>This APP will allow people to write in a journal, complete activities, track progress, read article and watch videos.</p>
                </div>
            </div>
        <div className="row">
            <div className="col">
                    <img className="aboutImgLeft" src="assets/images/juliesEnglishFlashCardCode.png" alt="managementHistory" />
            </div>
            <div className="col">
                <h2>The English app has a place to read small books, review flash cards and connect to a website with additional content. The books and flashcards are managed on a Firebase alternative, Back4app.</h2>
            </div>
        </div>
        <div className="row">
            <div className="col">
                    <img className="aboutImgLeft" src="assets/images/juliesEnglishCode.png" alt="managementHistory" />
            </div>
            <div className="col">
                <h2>The majority of the app is complete. Code-wise, it just needs UI tweaks and finishing touches. Mainly, it needs content before it can be launched.</h2>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h2>The Mental Health app has a place to journal, complete worksheets, meditate, read articles, and track progress. It also uses Back4App.</h2>
            </div>
            <div className="col">
                <img className="img-fluid aboutImgRight" src="assets/images/zhuYiCode.png" alt="managementHistory" />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h2>Aside from content, there is still a lot of work to do on this app. Much of the functionality is available, but disconnected to the rest of the app. There are still many elements and UI to complete.</h2>
            </div>
            <div className="col">
                <img className="img-fluid aboutImgRight" src="assets/images/zhuYiHomeCode.png" alt="managementHistory" />
            </div>
        </div>
    </div>
    </section>
}
