export function About(){

return <section>
    <div className="topSection">
        <h1 className="paddingH">Work History</h1>
    </div>
        <div className="row">
            <div className="col">
                <h2>I have a lot of management experience, including experience in project management.</h2>
            </div>
            <div className="col">
                <img className="aboutImgRight" src="assets/images/managementHistory.png" alt="managementHistory" />
            </div>
        </div>
        <div className="row">
            <div className="col">
                    <img className="aboutImgLeft" src="assets/images/ITHistory.png" alt="managementHistory" />
            </div>
            <div className="col">
                <h2>I've been involved with IT work for a long time. I've always had a knack for computers. Now, I'm pushing forward with a career in code.</h2>
            </div>
        </div>
            <div className="row">
                <div className="col">
                    <h2>I've completed many online courses, completed coding projects mostly in JavaScript, Flutter and Python.</h2>
                </div>
                <div className="col">
                    <img className="img-fluid aboutImgRight" src="assets/images/flutterCert.png" alt="managementHistory" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h2>Currently, I'm working on my own company to launch apps on the Chinese Market.</h2>
                </div>
                <div className="col">
                    <img className="img-fluid aboutImgRight" src="assets/images/sinoradoLogo.png" alt="managementHistory" />
                </div>
            </div>
</section>
}